import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Row } from "antd"
import "../styles/global.css"

/* This is the component that displays the business acknowledgements on the main page */
const Acknowledgements = () => (
  <div>
    <Row className="wrapper">
      <div className="heading">
        Support
        <Row className="wrapper" justify="space-around">
          <Col md={7} sm={7} xs={7}>
            <StaticImage
              alt="EnergyLab"
              src="https://s3-ap-southeast-2.amazonaws.com/fluxpower-website-images/EnergyLab.png"
              // width="100%"
            />
          </Col>
          <Col md={7} sm={7} xs={7}>
            <StaticImage
              alt="CEFC"
              src="https://fluxpower-website-images.s3-ap-southeast-2.amazonaws.com/CEFC.png"
              // width="100%"
            />
          </Col>
          <Col md={7} sm={7} xs={7}>
            <StaticImage
              alt="Artesian"
              src="https://s3-ap-southeast-2.amazonaws.com/fluxpower-website-images/Artesian.png"
              // width="100%"
            />
          </Col>
        </Row>
      </div>

      <Row className="wrapper" justify="space-around">
        <Col md={7} sm={7} xs={7}>
          <StaticImage
            alt="YC Startup School"
            src="https://s3-ap-southeast-2.amazonaws.com/fluxpower-website-images/YCstartupSchool.png"
            // width="100%"
          />
        </Col>
        <Col md={7} sm={7} xs={7}>
          <StaticImage
            alt="Queensland Farmers Federation"
            src="https://s3-ap-southeast-2.amazonaws.com/fluxpower-website-images/QFF.png"
            // width="100%"
          />
        </Col>
        <Col md={7} sm={7} xs={7}>
          <StaticImage
            alt="Advance QLD"
            src="https://fluxpower-website-images.s3-ap-southeast-2.amazonaws.com/AdvancedQLD.png"
            // width="100%"
          />
        </Col>
      </Row>
    </Row>
  </div>
)

export default Acknowledgements
