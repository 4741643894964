import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import "../styles/description.css"
import "../styles/global.css"
import { Row, Col } from "antd"

/*This has original text (keep this!!) */
// const Description = () => (
//   <Row className="wrapper">
//     <Col className="description-box" lg={24} sm={24} xs={24}>
//       <div className="heading">What is Fluxpower?</div>
//       <h4>
//         Fluxpower is a payments network for electricity markets, we help
//         suppliers pass on cheaper electricity to consumers
//       </h4>
//       <img
//         className="description_image_network"
//         alt="logo"
//         src="https://s3-ap-southeast-2.amazonaws.com/fluxpower-website-images/fluxpower+network.png"
//       />
//       <div className="heading">How does it work?</div>
//     </Col>
//     <Col className="description-box" lg={24} sm={24} xs={24}>
//       <h4>
//         <b>1) </b>we provide accounts to consumers: Households and Businesses,
//         and electricity suppliers: Retailers and Generators
//       </h4>
//       <img
//         className="description_image_oldlady"
//         alt="image_customers"
//         src="https://s3-ap-southeast-2.amazonaws.com/fluxpower-website-images/consumers%2C+retailers%2C+generators.png"
//       />
//     </Col>
//     <Col className="description-box" lg={24} sm={24} xs={24}>
//       <h4>
//         <b>2) </b> our software connects your meter with <b>real-time</b>{" "}
//         monitoring and allows you to authorise a FluxPay™ <b>smart contract</b>
//       </h4>
//       <img
//         className="description_image_contract"
//         padding-bottom="20px"
//         alt="image_smartcontract"
//         src="https://s3-ap-southeast-2.amazonaws.com/fluxpower-website-images/smart+meter%2C+smart+contract.png"
//       />
//     </Col>
//     <Col className="description-box" lg={24} sm={24} xs={24}>
//       <h4>
//         <b>3) </b> FluxPay transfers funds to your supplier as you consume
//         electricity, and from them to you as you feed back into the grid
//       </h4>
//       <img
//         className="description_image_bulb"
//         alt="image_bulb"
//         src="https://s3-ap-southeast-2.amazonaws.com/fluxpower-website-images/money%2C+bulb.png"
//       />
//     </Col>
//   </Row>
// )

// used <img> tag which is not optimised
// const Description = () => (
//   <Row className="wrapper">
//     <Col className="description-box" lg={24} sm={24} xs={24}>
//       <div className="heading">What is Fluxpower?</div>
//       <h4>
//         Fluxpower is a cleantech startup focused on providing better software
//         and apps to electricity suppliers and their customers
//       </h4>
//       <img
//         className="description_image_network"
//         alt="logo"
//         src="https://s3-ap-southeast-2.amazonaws.com/fluxpower-website-images/fluxpower+network.png"
//       />
//       <div className="heading">How does it work?</div>
//     </Col>
//     <Col className="description-box" lg={24} sm={24} xs={24}>
//       <h4>
//         <b>1) </b>we provide apps to retail consumers: Households and
//         Businesses, and electricity suppliers: Retailers and Generators
//       </h4>
//       <img
//         className="description_image_oldlady"
//         alt="image_customers"
//         src="https://s3-ap-southeast-2.amazonaws.com/fluxpower-website-images/consumers%2C+retailers%2C+generators.png"
//       />
//     </Col>
//     <Col className="description-box" lg={24} sm={24} xs={24}>
//       <h4>
//         <b>2) </b> our software connects smart meters and <b>real-time</b>{" "}
//         monitoring hardware to enable programatic <b>demand response</b>
//       </h4>
//       <img
//         className="description_image_contract"
//         padding-bottom="20px"
//         alt="image_smartcontract"
//         src="https://s3-ap-southeast-2.amazonaws.com/fluxpower-website-images/smart+meter%2C+smart+contract.png"
//       />
//     </Col>
//     <Col className="description-box" lg={24} sm={24} xs={24}>
//       <h4>
//         <b>3) </b>Electricity suppliers and the grid manage capacity easier, so
//         everyone saves money and reduces emissions
//       </h4>
//       <img
//         className="description_image_bulb"
//         alt="image_bulb"
//         src="https://s3-ap-southeast-2.amazonaws.com/fluxpower-website-images/money%2C+bulb.png"
//       />
//     </Col>
//   </Row>
// )

const Description = () => (
  <Row className="wrapper">
    <Col className="description-box" lg={24} sm={24} xs={24}>
      <div className="heading">What is Fluxpower?</div>
      <h4>
        Fluxpower is a cleantech startup that provides better integrated
        software and apps to electricity suppliers and their customers
      </h4>
      <StaticImage
        // className="description_image_network"
        alt="logo"
        src="https://s3-ap-southeast-2.amazonaws.com/fluxpower-website-images/fluxpower-network.png"
      />
      <div className="heading">How does it work?</div>
    </Col>
    <Col className="description-box" lg={24} sm={24} xs={24}>
      <h4>
        <b>1) </b>we provide apps to retail consumers: Households and
        Businesses, and electricity suppliers: Retailers and Generators
      </h4>
      <StaticImage
        // className="description_image_oldlady"
        alt="image_customers"
        src="https://s3-ap-southeast-2.amazonaws.com/fluxpower-website-images/consumers2retailers2generators.png"
      />
    </Col>
    <Col className="description-box" lg={24} sm={24} xs={24}>
      <h4>
        <b>2) </b> our software connects smart meters and <b>real-time</b>{" "}
        monitoring and control hardware to enable programatic{" "}
        <b>demand response</b>
      </h4>
      <StaticImage
        // className="description_image_contract"
        padding-bottom="20px"
        alt="image_smartcontract"
        src="https://s3-ap-southeast-2.amazonaws.com/fluxpower-website-images/smartmeter-smartcontract.png"
      />
    </Col>
    <Col className="description-box" lg={24} sm={24} xs={24}>
      <h4>
        <b>3) </b>Electricity consumers and suppliers help manage the grid
        better together, everyone saves money and reduces emissions
      </h4>
      <StaticImage
        // className="description_image_bulb"
        alt="image_bulb"
        src="https://s3-ap-southeast-2.amazonaws.com/fluxpower-website-images/money-bulb.png"
      />
    </Col>
  </Row>
)

export default Description
