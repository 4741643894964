import * as React from "react"

import SiteLayout from "../containers/sitelayout"
import Banner from "../containers/banner"
import Description from "../containers/description"
import Benefits from "../containers/benefits"
import Acknowledgements from "../containers/acknowledgements"
import Seo from "../components/seo"

const IndexPage = () => (
  <SiteLayout>
    <Seo title="Home" />
    <Banner />
    <Description />
    <Benefits />
    <Acknowledgements />
  </SiteLayout>
)

export default IndexPage
