import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Row, Button, Divider, Space } from "antd"
import { EditOutlined, MessageOutlined } from "@ant-design/icons"

import "../styles/global.css"

var mixpanel = require("mixpanel-browser")

const Banner = () => (
  <Row align="middle" justify="space-around">
    {/* Mobile image - image displays before/"above" text content*/}
    <Col xxl={0} xl={0} lg={0} md={23} sm={23} xs={23}>
      <StaticImage
        className="banner-image-mobile"
        alt="dashboard"
        src="https://s3-ap-southeast-2.amazonaws.com/fluxpower-website-images/Fluxpower+dashboard.png"
      />
    </Col>

    {/* Desktop text before/"to left of" image */}
    <Col
      xxl={{ span: 6, offset: 1 }}
      xl={{ span: 6, offset: 1 }}
      lg={{ span: 6, offset: 1 }}
      md={23}
      sm={23}
      xs={23}
    >
      <Row align="middle" justify="center">
        <Col
          xxl={{ span: 23, offset: 1 }}
          xl={{ span: 23, offset: 1 }}
          lg={{ span: 23, offset: 1 }}
          md={23}
          sm={23}
          xs={23}
        >
          <h1>Apps, AI, and Analytics for electricity markets</h1>
          <div>
            Monitor and control electricity, save money, and reduce CO2.
          </div>
        </Col>
      </Row>
      <div style={{ padding: 20 }}></div>
      <Row>
        <Col
          xxl={{ span: 24, offset: 1 }}
          xl={{ span: 24, offset: 1 }}
          lg={{ span: 24, offset: 1 }}
          md={23}
          sm={23}
          xs={23}
        >
          {" "}
          <Button
            block
            type="primary"
            size="large"
            target="_blank"
            rel="noopener noreferrer"
            href="https://web.fluxpower.io/"
            onClick={() => {
              mixpanel.track("Create Account")
            }}
          >
            <EditOutlined />
            CREATE ACCOUNT
          </Button>
        </Col>
      </Row>

      <Row>
        <Col
          xxl={{ span: 24, offset: 1 }}
          xl={{ span: 24, offset: 1 }}
          lg={{ span: 24, offset: 1 }}
          md={23}
          sm={23}
          xs={23}
        >
          <Divider>or</Divider>
        </Col>
      </Row>
      <Row>
        <Col
          xxl={{ span: 24, offset: 1 }}
          xl={{ span: 24, offset: 1 }}
          lg={{ span: 24, offset: 1 }}
          md={23}
          sm={23}
          xs={23}
        >
          <Button
            block
            size="large"
            href="mailto:contact@fluxpower.io?subject=I%20was%20browsing%20your%20website,%20I%20have%20a%20question:%20"
            rel="noopener noreferrer"
            onClick={() => {
              mixpanel.track("Call Support")
            }}
          >
            <MessageOutlined />
            CONTACT US
          </Button>
        </Col>
      </Row>
    </Col>

    {/* Desktop image - after/"to right of" text */}
    <Col
      xxl={{ span: 13, push: 1 }}
      xl={{ span: 13, push: 1 }}
      lg={{ span: 13, push: 1 }}
      md={0}
      sm={0}
      xs={0}
    >
      <StaticImage
        // className="banner-image-desktop"
        // width="100%"
        alt="logo"
        src="https://s3-ap-southeast-2.amazonaws.com/fluxpower-website-images/Fluxpower+dashboard.png"
      />
    </Col>
  </Row>
)

export default Banner
