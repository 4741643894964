import * as React from "react"
import { Tag, Row, Col, Space } from "antd"
import "../styles/benefits.css"
import "../styles/global.css"

/*This is the component that displays the benifits section on the main page*/

const Benefits = () => (
  <div className="wrapper-grey">
    <div className="heading">Benefits</div>
    <Row justify="center">
      <Col className="benifits_text_left" md={8} sm={6.5} xs={6.5}>
        <h4 className="benifits_line_left">
          <Tag color="magenta">Transparent</Tag>
        </h4>
        <h4 className="benifits_line_left">
          <Tag color="red">Easy</Tag>
        </h4>
        <h4 className="benifits_line_left">
          <Tag color="orange">Automatic</Tag>
        </h4>
        <h4 className="benifits_line_left">
          <Tag color="gold">Control</Tag>
        </h4>
        <h4 className="benifits_line_left">
          <Tag color="green">Real-Time</Tag>
        </h4>
        <h4 className="benifits_line_left">
          <Tag color="blue">Fun</Tag>
        </h4>
        <h4 className="benifits_line_left">
          <Tag color="purple">Enhance</Tag>
        </h4>
      </Col>
      <Space direction="vertical">
        <Col></Col>
      </Space>
      <Col xxl={11} xl={11} lg={13} md={13} sm={17.5} xs={17.5}>
        <div className="benifits_text_right">
          <Space direction="vertical">
            <h4 className="benifits_line_right">
              - See how much you are using and cost
            </h4>
            <h4 className="benifits_line_right">
              - Quick to setup and easy to use
            </h4>
            <h4 className="benifits_line_right">
              - AI that saves you time and money
            </h4>
            <h4 className="benifits_line_right">
              - You have the power to pause anytime
            </h4>
            <h4 className="benifits_line_right">
              - The right information at right time
            </h4>
            <h4 className="benifits_line_right">
              - Engaging and insightful for everyone
            </h4>
            <h4 className="benifits_line_right">
              - Improve tech at home and enjoy life more
            </h4>
          </Space>
        </div>
      </Col>
    </Row>
  </div>
)

export default Benefits
